
import './App.css';
import Home from './Components/home/index';



function App() {
  return (
    <Home/>
  );
}

export default App;
